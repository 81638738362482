import {
  LoginConfigOverride,
  TokenExpiredConfig,
} from '@pv-frontend/authentication';
import { ContextualGlobalDisplayConfig } from '@pv-frontend/contextual-journey';
import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';
import { OrderInfoModuleConfigOverride } from '@pv-frontend/order-info';
import { PointsLedgerConfigOverride } from '@pv-frontend/points-ledger';

import { authModuleConfig } from './authentication';
import {
  contextualDektopJourneyConfig,
  contextualMobileJourneyConfig,
} from './contextual';
import { giftVouchersConfig } from './gift-vouchers';
import { giftVouchersDesktopConfig } from './gift-vouchers-desktop';
import { orderInfoConfig } from './order-info';
import { orderInfoDesktop } from './order-info-desktop';
import { pointsLedgerDesktopConfig } from './points-ledger-desktop.config';
import { pointsLedger } from './points-ledger.config';

export interface AllJourneyConfig {
  journey: GiftVouchersConfig;
  contextual: ContextualGlobalDisplayConfig;
  orderInfo?: OrderInfoModuleConfigOverride;
  pointsLedger?: PointsLedgerConfigOverride;
  authentication?: LoginConfigOverride;
  tokenExpired?: TokenExpiredConfig;
}

export const allJourneyDetails: AllJourneyConfig = {
  journey:
    window.innerWidth < 768 ? giftVouchersConfig : giftVouchersDesktopConfig,
  contextual:
    window.innerWidth < 768
      ? contextualMobileJourneyConfig
      : contextualDektopJourneyConfig,
  orderInfo: window.innerWidth < 768 ? orderInfoConfig : orderInfoDesktop,
  pointsLedger:
    window.innerWidth < 768 ? pointsLedger : pointsLedgerDesktopConfig,
  authentication: authModuleConfig,
};
