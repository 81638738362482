import { LoginConfigOverride } from '@pv-frontend/authentication';

export const authModuleConfig: LoginConfigOverride = {
  authenticationFlow: {
    config: {
      flow: ['MOBILE_SEARCH', 'OTP_VERIFICATION'],
    },
  },
  drawer: {
    style: {},
    config: {
      dismissOnBackdropClick: true,
    },
  },
  emailSearch: {
    config: {
      skipEmailSearch: true,
      showBackIcon: false,
      otpTriggerFailureMessage: '',
    },
  },
  mobileSearch: {
    config: {
      skipMobileSearch: false,
      heading: 'Enter your registered mobile number',
      primaryHeading: 'Verify Mobile Number',
      mobileSearchErrorMessage: 'Please enter the registered mobile number',
      userCreationErrorMessage: 'Please enter a valid number',
      otpTriggerFailureMessage:
        'You have exceeded the maximum limit for OTP requests. Please try again after some time.',
      submitButtonLabel: 'CONTINUE',
      showBackIcon: false,
      shouldCreateUser: false,
      isExternalIdRequired: false,
      consentPrompt: {},
      isCountrySelectEnabled: true,
      placeholder: 'Enter your mobile number',
    },
    style: {
      primaryHeading: {
        fontSize: '16px',
        fontWeight: 700,
      },
      mobileInput: {
        container: {
          display: 'block',
          width: '100%',
        },
        inputBox: {
          height: '48px',
        },
        input: {
          fontSize: '1rem',
        },
        error: {
          marginTop: '8px',
          flexFlow: 'wrap',
          display: 'flex',
          width: '100%',
        },
      },
      label: {
        width: '100% !important',
        textAlign: 'left',
        fontWeight: 400,
        color: 'unset',
        fontSize: '0.875rem',
        marginRight: '20px',
      },
      labelContainer: {
        width: '100%',
        textAlign: 'center',
      },
      button: {
        fontSize: '16px',
        width: '60%',
        margin: 'auto',
      },
    },
  },
  binValidation: {
    config: {
      heading: '',
      shouldValidate: false,
      errorMessage: '',
      binType: 'first4',
      submitButtonLabel: '',
      binSeparator: '',
    },
  },
  otpVerification: {
    config: {
      skipOtpVerification: false,
      heading: 'Please enter the 6 digit OTP',
      errorMessage: 'Invalid OTP. Please enter the correct OTP.',
      resendOtpLabel: 'Did not receive OTP yet?',
      editMobileBtnLabel: 'Change number ?',
      countdownValue: 150,
      submitButtonLabel: 'Verify OTP',
      showNumberChange: true,
    },
    style: {
      resentOtpLabel: {
        width: '2rem',
      },
      otpInput: {
        input: {
          width: 'inherit',
          maxWidth: '4rem',
        },
        container: {
          width: '100%',
        },
        inputContainer: {
          width: '100%',
        },
        label: {
          fontSize: '0.875rem',
        },
      },
      countdown: {
        container: {
          fontSize: '0.75rem',
        },
      },
      button: {
        fontSize: '16px',
        width: '60%',
        margin: 'auto',
      },
    },
  },
  userCardSelection: {
    style: {
      continueButton: {
        borderRadius: '.125rem',
      },
      heading: {
        fontSize: '0.875rem',
      },
      cardTitle: {
        '-webkit-line-clamp': 2,
      },
      cardTitleClasses: 'pv-line-clamp-1',
    },
    config: {
      skipCardSelection: false,
      heading: 'Select your card',
      allowAddCard: false,
      addNewCardLabel: 'Add new card',
    },
  },
};
